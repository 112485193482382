:root {
  --f-BelF: "Bellefair", serif;
  --f-BarC: "Barlow Condensed", sans-serif;
  --f-Bar: "Barlow", sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --clr-dark: hsl(230, 35%, 7%);
  --clr-light: hsl(231, 77%, 90%);
  --clr-white: hsl(0, 0%, 100%);
  --clr-whiteOP0: hsla(0, 0%, 100%, 0);
  --clr-white0P5: hsla(0, 0%, 100%, 0.5);
  --clr-whiteOP3: hsla(0, 0%, 100%, 0.37);
  --clr-whiteOP2: hsla(0, 0%, 100%, 0.15);
  --clr-darkOP95: hsla(230, 35%, 7%, 0.95);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
  margin: 0;
}

body {
  background-color: var(--clr-dark) !important;
  color: var(--clr-white) !important;
  line-height: 1.5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#home {
  background-size: cover;
  background-position: bottom center;
  background-image: url(/assets/home/background-home-mobile.jpg);
}
@media (min-width: 35em) {
  #home {
    background-position: center center;
    background-image: url(/assets/home/background-home-tablet.jpg);
    background-repeat: no-repeat;
  }
}
@media (min-width: 59em) {
  #home {
    background-image: url(/assets/home/background-home-desktop.jpg);
  }
}

#destination {
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url(/assets/destination/background-destination-mobile.jpg);
}
@media (min-width: 35em) {
  #destination {
    background-repeat: no-repeat !important;
    background-image: url(/assets/destination/background-destination-tablet.jpg);
  }
}
@media (min-width: 59em) {
  #destination {
    background-repeat: no-repeat !important;
    background-image: url(/assets/destination/background-destination-desktop.jpg);
  }
}

#crew {
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url(/assets/crew/background-crew-tablet.jpg);
}
@media (min-width: 35em) {
  #crew {
    background-repeat: no-repeat !important;
    background-image: url(/assets/crew/background-crew-tablet.jpg);
  }
}
@media (min-width: 59em) {
  #crew {
    background-repeat: no-repeat !important;
    background-image: url(/assets/crew/background-crew-desktop.jpg);
  }
}

#technology {
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url(/assets/technology/background-technology-mobile.jpg);
}
@media (min-width: 35em) {
  #technology {
    background-repeat: no-repeat !important;
    background-image: url(/assets/technology/background-technology-tablet.jpg);
  }
}
@media (min-width: 59em) {
  #technology {
    background-repeat: no-repeat !important;
    background-image: url(/assets/technology/background-technology-desktop.jpg);
  }
}

.bg-dark {
  background-color: var(--clr-dark);
}

.bg-accent {
  background-color: var(--clr-light);
}

.bg-white {
  background-color: var(--clr-white);
}

.text-dark {
  color: var(--clr-dark);
}

.text-accent {
  color: var(--clr-light);
}

.text-white {
  color: var(--clr-white);
}

:root {
  /* font-sizes */
  --fs-900: 9.375rem;
  --fs-800: 6.25rem;
  --fs-700: 3.5rem;
  --fs-600: 2rem;
  --fs-500: 1.75rem;
  --fs-400: 1.125rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 59em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.primary-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1.5625rem;
  padding-left: 1.5625rem;
}

.primary-navigation {
  --underline-gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 39.9375em) {
  .primary-navigation {
    --underline-gap: .5rem;
    position: fixed;
    z-index: 1000;
    inset: 0 0 0 30%;
    background: black;
    list-style: none;
    padding: min(20rem, 15vh) 2rem;
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
}

.primary-navigation[data-visible=true] {
  transform: translateX(0);
}

@supports (backdrop-filter: blur(5px)) {
  .primary-navigation {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
}
.nav-links {
  font-family: var(--f-BarC);
  text-transform: uppercase;
  list-style-type: none;
  height: 100%;
}
.nav-links__li {
  margin-bottom: 1.875rem;
  letter-spacing: 0.16875rem;
  line-height: 1.1875rem;
  padding: var(--underline-gap, 1rem) 0;
  border-bottom: 0.2rem solid var(--clr-whiteOP0);
}
.nav-links__li:hover, .nav-links :focus {
  border-color: var(--clr-white0P5);
}
.nav-links__a {
  text-decoration: none;
  color: var(--clr-white) !important;
}
.nav-links .number {
  font-weight: bold;
  line-height: 1.1875rem;
  margin-right: 0.5rem;
}

.navbar-toggler {
  border: 0 !important;
  z-index: 2000;
  margin-right: 0.9375rem;
  background-position: center;
}

.navbar-toggle-icon {
  background-image: url("/assets/shared/icon-hamburger.svg") !important;
  display: inline-block;
  width: 1.5rem;
  height: 1.3125rem;
}

.navbar-toggle-icon.open {
  background-image: url("/assets/shared/icon-close.svg") !important;
  background-repeat: no-repeat;
  display: inline-block;
  width: 1.5rem;
  height: 1.3125rem;
  background-position: center;
}

@media (min-width: 59em) {
  #destination {
    text-align: left !important;
  }

  .content {
    margin-top: 2.5rem !important;
  }
}
@media (max-width: 39.9375em) {
  .content {
    margin-top: 2.5rem !important;
  }
}
@media (max-width: 48em) {
  .content {
    margin-top: 2.5rem !important;
  }
}
.wrapper {
  display: inline-grid;
  text-align: center;
  place-items: center;
  padding-inline: 1rem;
  margin-top: 3.125rem;
}

@media (max-width: 29.66em) {
  .content--header {
    color: var(--clr-light);
    font-family: var(--f-BarC);
    font-size: 1.1875rem !important;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.296875rem;
  }
  .content span {
    color: var(--clr-white) !important;
    font-family: var(--f-BelF);
    font-size: 5rem !important;
    line-height: 7.5rem !important;
  }
  .content--body {
    color: var(--clr-light);
    font-family: var(--f-Bar);
    padding: 0 !important;
    margin: 0 !important;
  }
}

.content--header {
  color: var(--clr-light);
  font-family: var(--f-BarC);
  font-size: 1.3125rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.296875rem;
}
.content span {
  color: var(--clr-white) !important;
  font-family: var(--f-BelF);
  font-size: 7.5rem;
  line-height: 11.25rem;
}
.content--body {
  color: var(--clr-light);
  font-family: var(--f-Bar);
  padding: 1.25rem 3.75rem 1.25rem 3.75rem;
  margin: 0rem 1.3125rem 0rem 1.3125rem;
}

.flex {
  display: flex;
  gap: var(--gap, 1rem);
  margin-left: 0.9375rem;
}

.btn-styles {
  color: var(--clr-dark);
  font-family: var(--f-BelF);
  font-size: 1.25rem;
  letter-spacing: 0.078125rem;
  text-transform: uppercase;
  margin-top: 3.75rem;
}

.large-button {
  background: var(--clr-white);
  position: relative;
  display: grid;
  z-index: 1;
  place-items: center;
  padding: 0 2em;
  border-radius: 50%;
  aspect-ratio: 1;
  text-decoration: none;
}

.large-button::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--clr-whiteOP2);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 500ms linear, transform 500ms ease-in-out;
}

.large-button:hover::after,
.large-button:focus::after {
  opacity: 1;
  transform: scale(1.5);
}

@media (min-width: 40em) {
  .primary-header .navbar-toggler {
    visibility: hidden;
    position: absolute;
  }
}
@media (min-width: 35em) {
  .primary-header {
    padding-top: 0 !important;
  }
  .primary-header .navbar {
    padding-top: 0 !important;
  }
  .primary-header__logo {
    padding-top: 0.75rem;
  }
  .primary-header .primary-navigation {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .primary-header .nav-links {
    font-family: var(--f-BarC);
    text-transform: uppercase;
    list-style-type: none;
    height: 100%;
  }
  .primary-header .nav-links__li {
    margin-top: 0.625rem;
    margin-bottom: 0rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    padding: var(--underline-gap, 1.5rem) 1rem;
    border-bottom: 0.2rem solid var(--clr-whiteOP0);
  }
  .primary-header .nav-links .active {
    border-color: var(--clr-white) !important;
  }
  .primary-header .nav-links__li:hover, .primary-header .nav-links :focus {
    border-color: var(--clr-white0P5);
  }
  .primary-header .nav-links__a {
    text-decoration: none;
    color: var(--clr-white);
  }
  .primary-header .nav-links .number {
    visibility: hidden;
    position: absolute;
  }
}

@media (min-width: 35em) {
  .wrapper {
    margin-top: 6.875rem !important;
  }
  .wrapper .content--body {
    padding: 1.25rem 6.5625rem 1.25rem 6.375rem;
    margin: 0rem 2.8125rem 0rem 2.8125rem;
  }
}
@media (min-width: 40em) {
  .wrapper .content--header {
    color: var(--clr-light);
    font-family: var(--f-BarC);
    font-size: 1.5625rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.296875rem;
  }
  .wrapper .content span {
    color: var(--clr-white);
    font-family: var(--f-BelF);
    font-size: 9.0625rem;
    line-height: 13.125rem;
  }
  .wrapper .content--body {
    padding: 1.25rem 7.5rem 1.25rem 7.5rem;
    margin: 0rem 2.8125rem 0rem 2.8125rem;
    font-size: 1.0625rem;
  }
}

@media (min-width: 59em) {
  .primary-header {
    padding-top: 2.5rem !important;
  }
  .primary-header--logo {
    margin-right: 3.75rem;
  }
  .primary-header .nav-links {
    font-family: var(--f-BarC);
    text-transform: uppercase;
    list-style-type: none;
    width: 60vh;
    height: 100%;
  }
  .primary-header .nav-links__li {
    margin-bottom: 0rem;
    margin-left: 0.3125rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    padding: var(--underline-gap, 1rem) 1.5625rem;
    border-bottom: 0.2rem solid var(--clr-whiteOP0);
  }
  .primary-header .nav-links .active {
    border-color: var(--clr-white) !important;
  }
  .primary-header .nav-links__li:hover, .primary-header .nav-links :focus {
    border-color: var(--clr-white0P5);
  }
  .primary-header .nav-links__a {
    text-decoration: none;
    color: var(--clr-white);
  }
  .primary-header .nav-links .number {
    visibility: visible;
    position: relative;
    font-weight: bold;
    line-height: 1.1875rem;
    margin-right: 0.625rem !important;
  }
}

@media (min-width: 59em) {
  .wrapper {
    display: grid;
    height: 70vh;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .wrapper .content {
    max-width: 28.125rem;
  }
  .wrapper .content--header {
    color: var(--clr-light);
    font-family: var(--f-BarC);
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.296875rem;
  }
  .wrapper .content span {
    color: var(--clr-white);
    font-family: var(--f-BelF);
    font-size: 7.5rem;
    line-height: 12.5rem;
  }
  .wrapper .content--body {
    padding: 0rem 0.625rem 1.25rem 0.625rem;
    margin: 0rem 1.5625rem 0rem 1.25rem;
    font-size: 1.0625rem;
  }
  .wrapper .flex {
    margin-top: 12.5rem;
  }
}

@media (max-width: 39.9375em) {
  section .display {
    height: 18.75rem !important;
  }
  section .display__header {
    font-family: var(--f-BarC) !important;
    font-size: 1.1875rem !important;
    margin: 0 !important;
    text-align: center !important;
    letter-spacing: 0.16875rem !important;
    text-transform: uppercase;
  }
  section .display__img {
    height: 10.625rem !important;
    width: 10.625rem !important;
    margin-top: 1.875rem !important;
    margin-left: 0rem !important;
    padding: 0 !important;
  }
  section .tabs {
    display: flex;
    justify-content: center;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    list-style-type: none;
    padding-left: 0.3125rem;
  }
  section .tabs__li {
    margin-right: 1.25rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    padding: var(--underline-gap, 0.3rem) 0;
    border-bottom: 0.2rem solid var(--clr-whiteOP0);
  }
  section .tabs .active {
    border-color: var(--clr-white) !important;
  }
  section .tabs__li:hover, section .tabs :focus {
    border-color: var(--clr-white0P5);
  }
  section .tabs__a {
    text-decoration: none;
    color: var(--clr-white);
  }
  section .planets__header {
    font-size: 3.4375rem !important;
    font-family: var(--f-BelF);
    text-transform: uppercase;
  }
  section .planets__content {
    color: var(--clr-light);
    font-size: 0.9375rem !important;
    line-height: 1.5625rem !important;
    margin-bottom: 1.875rem !important;
  }
  section .planets .line {
    display: block;
    border-bottom: 0.03125rem solid var(--clr-whiteOP3);
    margin-bottom: 1.875rem;
  }
  section .planets__flex {
    display: flex;
    flex-direction: column !important;
  }
  section .planets--distance1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin-right: 0 !important;
  }
  section .planets--distance2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 1.875rem !important;
  }
  section .planets--time1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }
  section .planets--time2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin: 0 !important;
  }
}

@media (min-width: 40em) {
  section .display {
    width: 100% !important;
  }
  section .display__header {
    font-family: var(--f-BarC) !important;
    font-size: 1.5rem !important;
    letter-spacing: 0.23125rem !important;
    text-transform: uppercase;
  }
  section .display__img {
    width: 80%;
    height: auto;
    margin-top: 2.5rem !important;
    margin-left: 0rem !important;
    padding: 0 !important;
  }
  section .tabs {
    display: flex;
    justify-content: center;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    list-style-type: none;
    padding-left: 0.3125rem;
  }
  section .tabs__li {
    margin-right: 1.25rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    padding: var(--underline-gap, 0.3rem) 0;
    border-bottom: 0.2rem solid var(--clr-whiteOP0);
  }
  section .tabs .active {
    border-color: var(--clr-white) !important;
  }
  section .tabs__li:hover, section .tabs :focus {
    border-color: var(--clr-white0P5);
  }
  section .tabs__a {
    text-decoration: none;
    color: var(--clr-white);
  }
  section .planets__header {
    font-size: 5rem !important;
    font-family: var(--f-BelF);
    text-transform: uppercase;
  }
  section .planets__content {
    color: var(--clr-light);
    font-size: 0.9375rem !important;
    line-height: 1.5625rem !important;
    padding-left: 4.0625rem;
    padding-right: 4.0625rem;
    margin-bottom: 1.875rem !important;
  }
  section .planets .line {
    display: block;
    border-bottom: 0.03125rem solid var(--clr-whiteOP3);
    margin-bottom: 1.875rem;
  }
  section .planets__flex {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
  }
  section .planets__distance {
    margin-right: 5rem;
  }
  section .planets--distance1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin-right: 0 !important;
  }
  section .planets--distance2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 1.875rem !important;
  }
  section .planets--time1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }
  section .planets--time2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin: 0 !important;
  }
}

section span {
  color: var(--clr-light) !important;
  margin-right: 1.25rem;
  font-weight: bold;
}
@media (min-width: 59em) {
  section .display__header {
    font-family: var(--f-BarC) !important;
    font-size: 1.625rem;
    color: var(--clr-white);
    text-transform: uppercase;
    letter-spacing: 0.25rem;
    text-align: start;
  }
  section .display__img {
    width: 27.8125rem !important;
    height: 27.8125rem !important;
    margin: 3.125rem 0rem 0rem 5rem;
    padding-bottom: 6.25rem;
  }
  section .tabs {
    display: flex;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.1875rem;
    list-style-type: none;
    padding-left: 0.3125rem;
  }
  section .tabs__li {
    margin-right: 1.25rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    padding: var(--underline-gap, 0.3rem) 0;
    border-bottom: 0.2rem solid var(--clr-whiteOP0);
  }
  section .tabs .active {
    border-color: var(--clr-white) !important;
  }
  section .tabs__li:hover, section .tabs :focus {
    border-color: var(--clr-white0P5);
  }
  section .tabs__a {
    text-decoration: none;
    color: var(--clr-white);
  }
  section .planets {
    min-height: 31.25rem !important;
  }
  section .planets__header {
    font-size: 6.25rem !important;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-top: 1.5625rem;
    text-align: center;
  }
  section .planets__content {
    font-size: 1.125rem;
    color: var(--clr-light);
    line-height: 2rem;
    margin-bottom: 3.125rem;
  }
  section .planets__flex {
    display: flex;
  }
  section .planets .line {
    display: block;
    border-bottom: 0.03125rem solid var(--clr-whiteOP3);
    margin-bottom: 1.875rem;
  }
  section .planets--distance1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    margin-right: 6.25rem;
  }
  section .planets--distance2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin: 0;
  }
  section .planets--time1 {
    font-size: 0.875rem !important;
    color: var(--clr-light);
    font-family: var(--f-BarC);
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }
  section .planets--time2 {
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin: 0 !important;
  }
}

@media (max-width: 39.9375em) {
  .header {
    grid-area: 1;
    font-family: var(--f-BarC);
    font-size: 1.0625rem !important;
    margin: 0 !important;
    text-align: center !important;
    letter-spacing: 0.16875rem !important;
    text-transform: uppercase;
  }
  .header span {
    color: var(--clr-light) !important;
    margin-right: 1.25rem;
    font-weight: 700;
  }
}
@media (max-width: 39.9375em) {
  section .display2__img2 {
    width: 80%;
    height: auto;
    margin-top: 2.5rem;
    border-bottom: 0.03125rem solid var(--clr-whiteOP3);
  }
  section .about {
    margin-top: 1.25rem;
  }
  section .about__title {
    color: var(--clr-white0P5);
    font-size: 1rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
  }
  section .about__name {
    color: var(--clr-white);
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 1.25rem;
  }
  section .about__text {
    color: var(--clr-light);
  }
  section .dots {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  section .dots .active {
    background-color: var(--clr-white) !important;
  }
  section .dots button {
    background-color: var(--clr-whiteOP3);
    border-radius: 50%;
    border: 0;
    aspect-ratio: 1;
    padding: 0.4375rem;
    margin: 0.625rem;
  }
  section .dots button:hover, section .dots :focus {
    background-color: var(--clr-white0P5);
  }
}

@media (min-width: 40em) {
  .content2 {
    display: flex;
    flex-direction: column-reverse;
    grid-area: 2;
  }

  .header {
    grid-area: 1;
    font-family: var(--f-BarC);
    font-size: 1.1875rem !important;
    margin: 0 !important;
    text-align: center !important;
    letter-spacing: 0.16875rem !important;
    text-transform: uppercase;
  }
  .header span {
    color: var(--clr-light) !important;
    margin-right: 1.25rem;
    font-weight: 700;
  }
}
@media (min-width: 40em) {
  section .display2__img2 {
    width: 60%;
    height: auto;
    margin-top: 2.5rem;
    border-bottom: 0.03125rem solid var(--clr-whiteOP3);
  }
  section .dots {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  section .dots .active {
    background-color: var(--clr-white) !important;
  }
  section .dots button {
    background-color: var(--clr-whiteOP3);
    border-radius: 50%;
    border: 0;
    aspect-ratio: 1;
    padding: 0.4375rem;
    margin: 0.625rem;
  }
  section .dots button:hover, section .dots :focus {
    background-color: var(--clr-white0P5);
  }
  section .about__title {
    color: var(--clr-white0P5);
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
  }
  section .about__name {
    color: var(--clr-white);
    font-size: 2.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 1.25rem;
  }
  section .about__text {
    padding-left: 4.0625rem;
    padding-right: 4.0625rem;
    color: var(--clr-light);
    margin-bottom: 1.25rem;
  }
}

@media (min-width: 59em) {
  .wrapper2 {
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-areas: "header header . ." "content content display display" "content content display display";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    text-align: center;
    padding-inline: 1rem;
    margin-top: 6.25rem;
  }

  .display2 {
    height: 130%;
    grid-area: display;
  }
  .display2__img2 {
    width: 95% !important;
    border: none !important;
  }

  .content2 {
    height: 10%;
    grid-area: content;
    max-width: 28.125rem;
  }
}
@media (min-width: 71.875em) {
  .content2 .about__title {
    color: var(--clr-white0P5);
    font-size: 1.6875rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    text-align: start;
    letter-spacing: 0.0625rem;
  }
  .content2 .about__name {
    color: var(--clr-white);
    font-size: 3.125rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    text-align: start;
    margin-bottom: 1.25rem;
  }
  .content2 .about__text {
    color: var(--clr-light);
    font-size: 1.125rem;
    text-align: start;
    padding: 0 !important;
    margin-bottom: 2.5rem;
  }
}
.wrapper3 {
  display: inline-grid;
  place-items: center;
  text-align: center;
  margin-top: 3.125rem;
}

@media (max-width: 39.9375em) {
  section .display2 {
    margin-top: 2.5rem !important;
  }
  section .display2__img {
    width: 100%;
    height: 10.625rem;
    margin-top: 1.875rem !important;
  }
  section .display2__imgDT {
    display: none;
  }
  section .display2__header {
    font-family: var(--f-BarC);
    font-size: 1.1875rem;
    margin: 0;
    text-align: center;
    letter-spacing: 0.16875rem;
    text-transform: uppercase;
  }
  section .dots2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  section .dots2 .active {
    background-color: var(--clr-white) !important;
    color: var(--clr-dark);
  }
  section .dots2 button {
    background-color: transparent;
    color: var(--clr-white);
    font-family: var(--f-BelF);
    font-size: 1.375rem;
    letter-spacing: 0.125rem;
    line-height: 0.0625rem;
    border-radius: 70%;
    border: 1px solid var(--clr-whiteOP3);
    padding: 1.25rem;
    margin: 0.625rem;
    width: 3.125rem;
    height: 3.125rem;
  }
  section .dots2 button:hover, section .dots2 :focus {
    background-color: var(--clr-white0P5);
  }
  section .about2__title {
    color: var(--clr-light);
    font-size: 1rem;
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.14375rem;
    margin-top: 1.5625rem;
  }
  section .about2__name {
    color: var(--clr-white);
    font-size: 1.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 0.625rem;
  }
  section .about2__text {
    color: var(--clr-light);
    line-height: 1.5625rem;
    padding: 0.625rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 48em) {
  section .display2 {
    margin-top: 2.5rem !important;
  }
  section .display2__img {
    width: 100%;
    height: auto;
    margin-top: 1.875rem !important;
  }
  section .display2__imgDT {
    display: none;
  }
  section .display2__header {
    font-family: var(--f-BarC);
    font-size: 1.375rem;
    margin-left: 2.5rem;
    text-align: start;
    letter-spacing: 0.16875rem;
    text-transform: uppercase;
  }
  section .dots2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  section .dots2 .active {
    background-color: var(--clr-white) !important;
    color: var(--clr-dark);
  }
  section .dots2 button {
    background-color: transparent;
    color: var(--clr-white);
    font-family: var(--f-BelF);
    font-size: 1.375rem;
    letter-spacing: 0.125rem;
    line-height: 0.0625rem;
    border-radius: 70%;
    border: 1px solid var(--clr-whiteOP3);
    padding: 1.25rem;
    margin: 0.625rem;
    width: 3.125rem;
    height: 3.125rem;
  }
  section .dots2 button:hover, section .dots2 :focus {
    background-color: var(--clr-white0P5);
  }
  section .about2__title {
    color: var(--clr-light);
    font-size: 1rem;
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.14375rem;
    margin-top: 1.5625rem;
  }
  section .about2__name {
    color: var(--clr-white);
    font-size: 2.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 0.625rem;
  }
  section .about2__text {
    color: var(--clr-light);
    line-height: 1.5625rem;
    padding: 0.625rem;
    margin-bottom: 1.25rem;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

@media (min-width: 59em) {
  .wrapper3 {
    height: 50vh;
    width: 100%;
    display: grid;
    margin: 2.5rem;
    grid-template-areas: "header header . ." "content . display display" "content . display display";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .header {
    padding-top: 5.3125rem;
  }

  .dots2 {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    grid-area: content;
  }

  .display2__img {
    display: none;
  }
  .display2__imgDT {
    width: 75%;
  }

  .dots2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 2.8125rem;
  }
  .dots2 .active {
    background-color: var(--clr-white) !important;
    color: var(--clr-dark);
  }
  .dots2 button {
    background-color: transparent;
    color: var(--clr-white);
    font-family: var(--f-BelF);
    font-size: 1.375rem;
    letter-spacing: 0.125rem;
    line-height: 0.0625rem;
    border-radius: 70%;
    border: 1px solid var(--clr-whiteOP3);
    padding: 1.25rem;
    margin: 0.625rem;
    width: 3.125rem;
    height: 3.125rem;
  }
  .dots2 button:hover, .dots2 :focus {
    background-color: var(--clr-white0P5);
  }

  .about2__title {
    color: var(--clr-light);
    font-size: 1rem;
    font-family: var(--f-BarC);
    text-transform: uppercase;
    letter-spacing: 0.14375rem;
    margin-top: 1.5625rem;
  }
  .about2__name {
    color: var(--clr-white);
    font-size: 2.5rem;
    font-family: var(--f-BelF);
    text-transform: uppercase;
    margin-bottom: 0.625rem;
  }
  .about2__text {
    color: var(--clr-light);
    line-height: 1.5625rem;
    margin-bottom: 1.25rem;
  }

  .display2__img {
    width: 100%;
    height: auto;
    margin-top: 1.875rem !important;
  }
}