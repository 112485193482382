:root {
	--white: hsl(0, 0%, 100%);
	--black: hsl(0, 0%, 0%);
	--clr-dark: hsl(230, 35%, 7%);
    --clr-light: hsl(231, 77%, 90%);
	--clr-white: hsl(0, 0%, 100%);
    --clr-whiteOP0: hsla(0, 0%, 100%, 0);
	--clr-white0P5: hsla(0, 0%, 100%, 0.5);
	--clr-whiteOP3: hsla(0, 0%, 100%, 0.37);
	--clr-whiteOP2: hsla(0, 0%, 100%, 0.15);
	--clr-darkOP95: hsla(230, 35%, 7%, 0.95);
}
