// 640px, 1024px, 1150px, 1400px
$breakpoints-up: (
	'xsmall': '30em',
	'small': '35em',
	'medium': '40em',
	'xmedium': '64em',
	'large': '59em',
	'xlarge': '71.875em',
	'xxlarge': '87.5em',
);

// 320px, 375px 425px, 639px, 768px, 1149px, 1399px
$breakpoints-down: (
	'xxxsmall': '20em',
	'xxsmall': '23.6em',
	'xsmall': '29.66em',
	'small': '39.9375em',
	'medium': '48em',
	'custom': '86.75em',
	'large': '63.98em',
	'xlarge': '71.8125em',
	'xxlarge': '87.4375em',
);

@mixin breakpoint($size) {
	@media (min-width: map-get($breakpoints-up, $size)) {
		@content;
	}
}

@mixin breakpoint-down($size) {
	@media (max-width: map-get($breakpoints-down, $size)) {
		@content;
	}
}
