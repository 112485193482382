@use '../util' as *;

:root {

/* font-sizes */
--fs-900: 9.375rem;
--fs-800: 6.25rem;
--fs-700: 3.5rem;
--fs-600: 2rem;
--fs-500: 1.75rem;
--fs-400: 1.125rem;
--fs-300: 1rem;
--fs-200: 0.875rem;
}




h1,
h2,
h3 {
	margin-top: 0;
	line-height: 1.1;
}

a,
a:visited,
a:active {
	text-decoration: none;
}
