@use '../util' as *;



@include breakpoint-down(small){
    .header {
        grid-area: 1;
        font-family: var(--f-BarC);
        font-size: rem(17) !important;
        margin: 0 !important;
        text-align: center !important;
        letter-spacing: rem(2.7) !important;
        text-transform: uppercase;
    
        span {
            color: var(--clr-light) !important;
            margin-right: rem(20);
            font-weight: 700;
        }
    }
}


section {
    @include breakpoint-down(small) {
        .display2 {
            &__img2 {
                width: 80%;
                height: auto;
                margin-top: rem(40);
                border-bottom: rem(.5) solid var(--clr-whiteOP3);
            }
        }

        .about {
            margin-top: rem(20);
            &__title {
                color: var(--clr-white0P5);
                font-size: rem(16);
                font-family: var(--f-BelF);
                text-transform: uppercase;
                letter-spacing: rem(1);
            }

            &__name {
                color: var(--clr-white);
                font-size: rem(24);
                font-family: var(--f-BelF);
                text-transform: uppercase;
                margin-bottom: rem(20);
            }

            &__text {
                color: var(--clr-light);
            }

        }

        .dots {
            cursor: pointer;
            display: flex;
            justify-content: center;

            .active {
                background-color: var(--clr-white) !important;
            }
            
            button {
                background-color: var(--clr-whiteOP3);
                border-radius: 50%;
                border: 0;
                aspect-ratio: 1;
                padding: rem(7);
                margin: rem(10);
            }

            button:hover,:focus {
                background-color: var(--clr-white0P5);
            }

        }
    }
}


// grid style class for 670 - 786px 
@include breakpoint(medium){
    .content2 {
        display: flex;
        flex-direction: column-reverse;
        grid-area: 2;
    }

    .header {
        grid-area: 1;
        font-family: var(--f-BarC);
        font-size: rem(19) !important;
        margin: 0 !important;
        text-align: center !important;
        letter-spacing: rem(2.7) !important;
        text-transform: uppercase;

        span {
            color: var(--clr-light) !important;
            margin-right: rem(20);
            font-weight: 700;
        }
    }
}

// medium breakpoint vp 
section {
    @include breakpoint(medium){
        .display2 {
            &__img2 {
                width: 60%;
                height: auto;
                margin-top: rem(40);
                border-bottom: rem(.5) solid var(--clr-whiteOP3);
            }


        }

        .dots {
            cursor: pointer;
            display: flex;
            justify-content: center;

            .active {
                background-color: var(--clr-white) !important;
            }
            
            button {
                background-color: var(--clr-whiteOP3);
                border-radius: 50%;
                border: 0;
                aspect-ratio: 1;
                padding: rem(7);
                margin: rem(10);
            }

            button:hover,:focus {
                background-color: var(--clr-white0P5);
            }

        }

        .about {
            
            &__title {
                color: var(--clr-white0P5);
                font-size: rem(24);
                font-family: var(--f-BelF);
                text-transform: uppercase;
                letter-spacing: rem(1);
            }

            &__name {
                color: var(--clr-white);
                font-size: rem(40);
                font-family: var(--f-BelF);
                text-transform: uppercase;
                margin-bottom: rem(20);
            }

            &__text {
                padding-left: rem(65);
                padding-right: rem(65);
                color: var(--clr-light);
                margin-bottom: rem(20);
            }

        }
    }
}
